import React, { useState } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  Upload,
  Popover,
} from "antd";
import PageHeader from "../../../components/pageHeader";
import UploadUtils from "../../../utilities/uploadUtils";
import {

  QuestionCircleOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import AuthChecker from "../../../components/authChecker";
import roleAndPermissions from "../../../constants/permissions"
import ShippingInFormationPageView from "../pages-view/ShippingInFormationPageView";
const { TextArea } = Input;

const ShippingInformationForm = ({ mode, formFor, editLayout, title }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(formFor?.en?.data);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      values.feature_image = UploadUtils.processUploadImage(
        values.feature_image
      );
      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          feature_image: values.feature_image,
        },
      ];

      setData(values);
     await editLayout(values, "Shipping Information", seo_setting);
     setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  if (mode !== "View") {
    return (
      <Form
        form={form}
        disabled={mode === "View" || loading === true}
        layout="vertical"
        initialValues={data}
        onFinish={(values) => handleSubmit(values)}
      >
        <PageHeader title={`${title?.replace("-", " ")} Page`} />
        <Row gutter={16} className="mt30">
          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Shipping Information Main Title">
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Shipping Information Description"
                name={"description"}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <ReactQuill theme="snow" rows={4} readOnly={mode === "View" ? true : false} />
              </Form.Item>
            </Card>
          </Col>

          <AuthChecker
            notPermissionCase={
              <Col span={24} style={{ marginBottom: 30 }}>
                <Card size="small" title="Search Engine Listing">
                  <Form.Item
                    label="Meta Title"
                    name="meta_title"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label="Meta Description"
                    name="meta_description"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                  
                    <TextArea disabled />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        Feature Image{" "}
                        <Popover
                          placement="right"
                          title="W * H"
                          content="1200 x 675 OR 1600 x 900"
                        >
                          <QuestionCircleOutlined />
                        </Popover>
                      </span>
                    }
                    name="feature_image"
                    {...UploadUtils.formItemProps}
                    wrapperCol={{ span: 24 }}
                  >
                    <Upload {...UploadUtils.buttonPreviewProps} disabled>
                      <UploadUtils.CardContent />
                    </Upload>
                  </Form.Item>
                </Card>
              </Col>}
            permissions={[roleAndPermissions.PageManagement.createSEO, roleAndPermissions.PageManagement.updateSEO]}>

            <Col span={24} style={{ marginBottom: 30 }}>
              <Card size="small" title="Search Engine Listing">
                <Form.Item
                  label="Meta Title"
                  name="meta_title"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Meta Description"
                  name="meta_description"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <TextArea />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      Feature Image{" "}
                      <Popover
                        placement="right"
                        title="W * H"
                        content="1200 x 675 OR 1600 x 900"
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </span>
                  }
                  name="feature_image"
                  {...UploadUtils.formItemProps}
                  wrapperCol={{ span: 24 }}
                >
                  <Upload {...UploadUtils.buttonPreviewProps}>
                    <UploadUtils.CardContent />
                  </Upload>
                </Form.Item>
              </Card>
            </Col>
          </AuthChecker>

          <Col span={24}>
            <Flex justify="end" style={{ padding: 24, paddingRight: 0 }} gap={16}>
              <Form.Item noStyle>
                <Button type="primary" htmlType="submit" loading={loading}
                  disabled={loading}>
                  Update Page
                </Button>
              </Form.Item>
            </Flex>
          </Col>
        </Row>
      </Form>
    );
  }
  if (mode === "View") {
    return (
      <> <PageHeader title={`${title?.replace("-", " ")} Page`} />
        <ShippingInFormationPageView data={data} />
      </>
    );
  }
};

export default ShippingInformationForm;
