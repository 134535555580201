import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  Upload,
  InputNumber,
  DatePicker,
  Popover,
} from "antd";
import PageHeader from "../../../components/pageHeader";
import Dragger from "antd/es/upload/Dragger";
import UploadUtils from "../../../utilities/uploadUtils";
import ReactQuill from "react-quill";
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import OEMInfoSolutionSort from "../../../components/wholesaler-draggable/OEMInfoSolutionSort";
import WholeSaleWhyYouChooseSort from "../../../components/wholesaler-draggable/WholeSaleWhyYouChooseSort";
import SalesmanSort from "../../../components/wholesaler-draggable/SalesmanSort";
import roleAndPermissions from "../../../constants/permissions"
import AuthChecker from "../../../components/authChecker"
import ForWholeSalerPageView from "../pages-view/ForWholeSalerPageView";

const { TextArea } = Input;

const ForWholeSalerForms = ({ mode, formFor, editLayout, title }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(formFor?.en?.data);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      values.for_wholesaler_image = UploadUtils.processUploadImage(
        values.for_wholesaler_image
      );
      values.wholesale_image = UploadUtils.processUploadImage(
        values.wholesale_image
      );
      values.seller_image = UploadUtils.processUploadImage(values.seller_image);
      values.why_you_choose = values.why_you_choose.map((i) => ({
        name: i.name,
        image: UploadUtils.processUploadImage(i.image),
        // description: i.description,
      }));
      values.oem_info_solutions = values.oem_info_solutions.map((i) => ({
        name: i.name,
        image: UploadUtils.processUploadImage(i.image),
        // description: i.description,
      }));

      values.feature_image = UploadUtils.processUploadImage(
        values.feature_image
      );

      values.salesman = values.salesman.map((i) => ({
        salesman_name: i.salesman_name,
        salesman_rating: i.salesman_rating,
        salesman_review: i.salesman_review,
        salesman_info: i.salesman_info,
        salesman_image: UploadUtils.processUploadImage(i.salesman_image),
      }));

      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          feature_image: values.feature_image,
        },
      ];
      setData(data);
     await editLayout(values, "For Wholesalers", seo_setting);
     setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }

    // console.log(values);
  };
  if (mode !== "View") {
    return (
      <Form
        form={form}
        disabled={mode === "View" || loading === true}
        layout="vertical"
        initialValues={data}
        onFinish={(values) => handleSubmit(values)}
      >
        <PageHeader title={`${title.replace("-", " ")} Page`} />
        <Row gutter={16} className="mt30">
          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="For Wholesalers Section">
              <Form.Item
                label="For Wholesaler Title"
                name="for_wholesaler_title"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="For Wholesaler Description"
                name="for_wholesaler_description"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <ReactQuill theme="snow" rows={4} readOnly={mode === "View" ? true : false} />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    For Wholesaler Image{" "}
                    <Popover placement="right" title="W * H" content="736 x 664">
                      <QuestionCircleOutlined />
                    </Popover>
                  </span>
                }
                name="for_wholesaler_image"
                {...UploadUtils.formItemProps}
                wrapperCol={{ span: 24 }}
              >
                <Upload {...UploadUtils.buttonPreviewProps}>
                  <UploadUtils.CardContent />
                </Upload>
              </Form.Item>
            </Card>
          </Col>

          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="OEM Infotainment Solutions Section">
              <Form.Item
                label="OEM Infotainment Solutions Title"
                name="oem_infotainment_solutions_title"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="OEM Infotainment Solutions Description"
                name="oem_infotainment_solutions_description"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <ReactQuill theme="snow" rows={4} readOnly={mode === "View" ? true : false} />
              </Form.Item>
              <OEMInfoSolutionSort data={data} setData={setData} form={form} />

              {/* <Form.List name="oem_info_solutions">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "row",
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      size="small"
                      title={`OEM Info Solutions ${index + 1}`}
                      key={field.key}
                    >
                      <Form.Item
                        label="Name"
                        name={[field.name, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Image"
                        name={[field.name, "image"]}
                        {...UploadUtils.formItemProps}
                        wrapperCol={{ span: 24 }}
                      >
                        <Upload {...UploadUtils.buttonPreviewProps}>
                          <UploadUtils.CardContent />
                        </Upload>
                      </Form.Item>
                    </Card>
                  ))}
                  
                </div>
              )}
            </Form.List> */}
            </Card>
          </Col>

          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Why You Choose Us Section">
              <Form.Item
                label="Why You Choose Us Section Title"
                name="why_you_choose_us_section_title"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <WholeSaleWhyYouChooseSort
                data={data}
                setData={setData}
                form={form}
              />
              {/* <Form.List name="why_you_choose" initialValue={[]}>
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "row",
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      size="small"
                      title={`Why You Choose ${index + 1}`}
                      key={field.key}
                      // extra={
                      //   <>
                      //     {fields.length > 1 && (
                      //       <MinusCircleOutlined
                      //         onClick={() => {
                      //           remove(field.name);
                      //         }}
                      //       />
                      //     )}
                      //   </>
                      // }
                    >
                      <Form.Item
                        label="Name"
                        name={[field.name, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Image"
                        name={[field.name, "image"]}
                        {...UploadUtils.formItemProps}
                        wrapperCol={{ span: 24 }}
                      >
                        <Upload {...UploadUtils.buttonPreviewProps}>
                          <UploadUtils.CardContent />
                        </Upload>
                      </Form.Item>
                    </Card>
                  ))}
                 
                </div>
              )}
            </Form.List> */}
            </Card>
          </Col>

          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Seller Stories">
              <div
                style={{
                  display: "flex",
                  gap: 30,
                  flexDirection: "column",
                }}
              >
                <Card size="small" title="Seller Section">
                  <Form.Item
                    label="Seller Title "
                    name="seller_title"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        Seller Image{" "}
                        <Popover placement="right" title="W * H" content="64 x 64">
                          <QuestionCircleOutlined />
                        </Popover>
                      </span>
                    }
                    name="seller_image"
                    {...UploadUtils.formItemProps}
                    wrapperCol={{ span: 24 }}
                  >
                    <Upload {...UploadUtils.buttonPreviewProps}>
                      <UploadUtils.CardContent />
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    label="Seller Address"
                    name={"seller_address"}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Seller DepartMent"
                    name={"seller_department"}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Seller Description"
                    name="seller_description"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <ReactQuill theme="snow" rows={4} readOnly={mode === "View" ? true : false} />
                  </Form.Item>
                  <Form.Item
                    label="Seller Number"
                    name={"seller_number"}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Seller Email"
                    name={"seller_email"}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Card>
                <Card size="small" title="Salesman Section">
                  <SalesmanSort data={data} setData={setData} form={form} />
                  {/* <Form.List name="salesman">
                  {(fields, { add, remove }) => (
                    <div
                      style={{
                        display: "flex",
                        gap: 30,
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: 20,
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {fields.map((field, index) => (
                          <Card
                            size="small"
                            title={`salesman ${index + 1}`}
                            extra={
                              <MinusCircleOutlined
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            }
                            style={{ maxWidth: 380 }}
                            key={field.key}
                          >
                            <Form.Item
                              label="Salesman Image"
                              name={[field.name, "salesman_image"]}
                              {...UploadUtils.formItemProps}
                              wrapperCol={{ span: 24 }}
                            >
                              <Upload {...UploadUtils.buttonPreviewProps}>
                                <UploadUtils.CardContent />
                              </Upload>
                            </Form.Item>
                            <Form.Item
                              label="Salesman Name"
                              name={[field.name, "salesman_name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Salesman Info"
                              name={[field.name, "salesman_info"]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Salesman Rating"
                              name={[field.name, "salesman_rating"]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <InputNumber min={0} max={5} />
                            </Form.Item>
                            <Form.Item
                              label="Salesman Review"
                              name={[field.name, "salesman_review"]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <TextArea />
                            </Form.Item>
                          </Card>
                        ))}
                      </div>
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Salesman
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </Form.List> */}
                </Card>
              </div>
            </Card>
          </Col>

          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Get A Wholesale Account">
              <Form.Item
                label={
                  <span>
                    Wholesale Image{" "}
                    <Popover placement="right" title="W * H" content="736 x 392">
                      <QuestionCircleOutlined />
                    </Popover>
                  </span>
                }
                name={"wholesale_image"}
                {...UploadUtils.formItemProps}
                wrapperCol={{ span: 24 }}
              >
                <Upload {...UploadUtils.buttonPreviewProps}>
                  <UploadUtils.CardContent />
                </Upload>
              </Form.Item>
              <Form.Item
                label="Get A Wholesale Account Title"
                name="get_wholesale_account_title"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Get A Wholesale Account Description"
                name="get_wholesale_account_description"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <ReactQuill theme="snow" rows={4} readOnly={mode === "View" ? true : false} />
              </Form.Item>
            </Card>
          </Col>

          <AuthChecker notPermissionCase={
            <Col span={24} style={{ marginBottom: 30 }}>
              <Card size="small" title="Search Engine Listing">
                <Form.Item
                  label="Meta Title"
                  name="meta_title"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="Meta Description"
                  name="meta_description"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <TextArea disabled />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      Feature Image{" "}
                      <Popover
                        placement="right"
                        title="W * H"
                        content="1200 x 675 OR 1600 x 900"
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </span>
                  }
                  name="feature_image"
                  {...UploadUtils.formItemProps}
                  wrapperCol={{ span: 24 }}
                >
                  <Upload {...UploadUtils.buttonPreviewProps} disabled>
                    <UploadUtils.CardContent />
                  </Upload>
                </Form.Item>
              </Card>
            </Col>} permissions={[roleAndPermissions.PageManagement.createSEO, roleAndPermissions.PageManagement.updateSEO]}>
            <Col span={24} style={{ marginBottom: 30 }}>
              <Card size="small" title="Search Engine Listing">
                <Form.Item
                  label="Meta Title"
                  name="meta_title"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Meta Description"
                  name="meta_description"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <TextArea />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      Feature Image{" "}
                      <Popover
                        placement="right"
                        title="W * H"
                        content="1200 x 675 OR 1600 x 900"
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </span>
                  }
                  name="feature_image"
                  {...UploadUtils.formItemProps}
                  wrapperCol={{ span: 24 }}
                >
                  <Upload {...UploadUtils.buttonPreviewProps}>
                    <UploadUtils.CardContent />
                  </Upload>
                </Form.Item>
              </Card>
            </Col>
          </AuthChecker>
          <Col span={24}>
            <Flex justify="end" style={{ padding: 24, paddingRight: 0 }} gap={16}>
              <Form.Item noStyle>
                <Button type="primary" htmlType="submit" loading={loading}
                  disabled={loading}>
                  Update Page
                </Button>
              </Form.Item>
            </Flex>
          </Col>
        </Row>
      </Form>
    );
  }
  if (mode === "View") {
      return (
        <> <PageHeader title={`${title?.replace("-", " ")} Page`} />
          <ForWholeSalerPageView data={data} />
        </>
      );
    }
};

export default ForWholeSalerForms;
