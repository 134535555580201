const permissions = {
  CustomerManagement: {
    view: "View User",
    create: "Create User",
    update: "Update User",
    delete: "Delete User",
    createComment: "Create User Comment",
    updateComment: "Update User Comment",
    deleteComment: "Delete User Comment",
    exportData: "Export User",
  },
  RoleAndPermissionManagement: {
    view: "View Role",
    create: "Create Role",
    update: "Update Role",
    delete: "Delete Role",
    viewPermission: "View Permission",
  },
  AdminUserManagement: {
    view: "View Admin User",
    create: "Create Admin User",
    update: "Update Admin User",
    delete: "Delete Admin User",
    createComment: "Create Admin User Comment",
    updateComment: "Update Admin User Comment",
    deleteComment: "Delete Admin User Comment",
  },
  ProductCategory: {
    view: "View Product Category",
    create: "Create Product Category",
    update: "Update Product Category",
    delete: "Delete Product Category",
    createSEO: "Create Product Category SEO",
    updateSEO: "Update Product Category SEO",
    exportData: "Export Product Category",
  },
  ProductCollection: {
    view: "View Product Collection",
    create: "Create Product Collection",
    update: "Update Product Collection",
    delete: "Delete Product Collection",
  },
  ProductCatalog: {
    view: "View Product Catalog",
    create: "Create Product Catalog",
    update: "Update Product Catalog",
    delete: "Delete Product Catalog",
    createSEO: "Create Product Catalog SEO",
    updateSEO: "Update Product Catalog SEO",
    exportData: "Export Product Catalog",
  },
  ProductType: {
    view: "View Product Type",
    create: "Create Product Type",
    update: "Update Product Type",
    delete: "Delete Product Type",
    createSEO: "Create Product Type SEO",
    updateSEO: "Update Product Type SEO",
  },
  ProductBrand: {
    view: "View Product Brand",
    create: "Create Product Brand",
    update: "Update Product Brand",
    delete: "Delete Product Brand",
    createSEO: "Create Product Brand SEO",
    updateSEO: "Update Product Brand SEO",
    exportData: "Export Product Brand",
  },
  ProductTag: {
    view: "View Product Tag",
    create: "Create Product Tag",
    update: "Update Product Tag",
    delete: "Delete Product Tag",
    createSEO: "Create Product Tag SEO",
    updateSEO: "Update Product Tag SEO",
  },
  ProductManagement: {
    view: "View Product",
    create: "Create Product",
    update: "Update Product",
    delete: "Delete Product",
    updateStatus: "Update Product Status",
    createSEO: "Create Product SEO",
    updateSEO: "Update Product SEO",
    exportData: "Export Product",
  },
  ProductAttribute: {
    view: "View Product Attribute",
    create: "Create Product Attribute",
    update: "Update Product Attribute",
    delete: "Delete Product Attribute",
  },
  Vendor: {
    view: "View Vendor",
    create: "Create Vendor",
    update: "Update Vendor",
    delete: "Delete Vendor",
    exportData: "Export Vendor",
  },
  Warehouse: {
    view: "View Warehouse",
    create: "Create Warehouse",
    update: "Update Warehouse",
    delete: "Delete Warehouse",
  },
  ProductInventory: {
    view: "View Product Inventory",
    create: "Create Product Inventory",
    update: "Update Product Inventory",
    delete: "Delete Product Inventory",
  },
  PurchaseOrder: {
    view: "View Purchase Order",
    create: "Create Purchase Order",
    update: "Update Purchase Order",
    delete: "Delete Purchase Order",
    createComment: "Create Purchase Order Comment",
    updateComment: "Update Purchase Order Comment",
    deleteComment: "Delete Purchase Order Comment",
  },
  BlogManagement: {
    view: "View Blog",
    create: "Create Blog",
    update: "Update Blog",
    delete: "Delete Blog",
    createSEO: "Create Blog SEO",
    updateSEO: "Update Blog SEO",
  },
  BlogCategoryManagement: {
    view: "View Blog Category",
    create: "Create Blog Category",
    update: "Update Blog Category",
    delete: "Delete Blog Category",
    createSEO: "Create Blog Category SEO",
    updateSEO: "Update Blog Category SEO",
  },
  BlogTagManagement: {
    view: "View Blog Tag",
    create: "Create Blog Tag",
    update: "Update Blog Tag",
    delete: "Delete Blog Tag",
    createSEO: "Create Blog Tag SEO",
    updateSEO: "Update Blog Tag SEO",
  },
  PageManagement: {
    view: "View CMS Page",
    create: "Create CMS Page",
    update: "Update CMS Page",
    delete: "Delete CMS Page",
    createSEO: "Create Page SEO",
    updateSEO: "Update Page SEO",
    // createSEO: "Create CMS Page SEO",
    // updateSEO: "Update CMS Page SEO",
  },
  Order: {
    view: "View Order",
    create: "Create Order",
    update: "Update Order",
    delete: "Delete Order",
    updateStatus: "Update Order Status",
    createComment: "Create Order Comment",
    updateComment: "Update Order Comment",
    deleteComment: "Delete Order Comment",
    exportData:"Export Order"
  },
  // InventoryTransfer: {
  //     view: 'View Inventory Transfer',
  //     create: 'Create Inventory Transfer',
  //     update: 'Update Inventory Transfer',
  //     delete: 'Delete Inventory Transfer',
  //     createComment: 'Create Inventory Transfer Comment',
  //     updateComment: 'Update Inventory Transfer Comment',
  //     deleteComment: 'Delete Inventory Transfer Comment',
  // },
  InventoryInternalTransfer: {
    view: "View External Inventory Transfer",
    create: "Create External Inventory Transfer",
    update: "Update External Inventory Transfer",
    delete: "Delete External Inventory Transfer",
    createComment: "Create External Inventory Transfer Comment",
    updateComment: "Update External Inventory Transfer Comment",
    deleteComment: "Delete External Inventory Transfer Comment",
  },
  InventoryExternalTransfer: {
    view: "View Internal Inventory Transfer",
    create: "Create Internal Inventory Transfer",
    update: "Update Internal Inventory Transfer",
    delete: "Delete Internal Inventory Transfer",
    createComment: "Create Internal Inventory Transfer Comment",
    updateComment: "Update Internal Inventory Transfer Comment",
    deleteComment: "Delete Internal Inventory Transfer Comment",
  },
  AbandonedCheckouts: {
    view: "View Order",
    create: "Create Order",
    update: "Update Order",
    delete: "Delete Order",
    updateStatus: "Update Order Status",
    createComment: "Create Order Comment",
    updateComment: "Update Order Comment",
    deleteComment: "Delete Order Comment",
  },
  ProductReviews: {
    view: "View Product Review",
    create: "Create Product Review",
    update: "Update Product Review",
    delete: "Delete Product Review",
    approve: "Approve Product Review",
    exportData: "Export Review",
  },
  InventoryAdjust: {
    view: "Create Inventory Adjustment",
    create: "View Inventory Adjustment",
    update: "Update Inventory Adjustment",
    delete: "Delete Inventory Adjustment",
    createComment: "Create Inventory Adjustment Comment",
    updateComment: "Update Inventory Adjustment Comment",
    deleteComment: "Delete Inventory Adjustment Comment",
  },
  OrdersBatch: {
    view: "View Order",
    create: "Create Order",
    update: "Update Order",
    delete: "Delete Order",
    updateStatus: "Update Order Status",
    createComment: "Create Order Comment",
    updateComment: "Update Order Comment",
    deleteComment: "Delete Order Comment",
  },
  OrdersPicking: {
    view: "View Order",
    create: "Create Order",
    update: "Update Order",
    delete: "Delete Order",
    updateStatus: "Update Order Status",
    createComment: "Create Order Comment",
    updateComment: "Update Order Comment",
    deleteComment: "Delete Order Comment",
  },
  OrdersPacking: {
    view: "View Order",
    create: "Create Order",
    update: "Update Order",
    delete: "Delete Order",
    updateStatus: "Update Order Status",
    createComment: "Create Order Comment",
    updateComment: "Update Order Comment",
    deleteComment: "Delete Order Comment",
  },
  OrdersShipping: {
    view: "View Order",
    create: "Create Order",
    update: "Update Order",
    delete: "Delete Order",
    updateStatus: "Update Order Status",
    createComment: "Create Order Comment",
    updateComment: "Update Order Comment",
    deleteComment: "Delete Order Comment",
  },
  ProductCompatibility: {
    view: "View Product Compatibility",
    create: "Create Product Compatibility",
    update: "Update Product Compatibility",
    delete: "Delete Product Compatibility",
    createSEO: "Create Product Compatibility SEO",
    updateSEO: "Update Product Compatibility SEO",
  },
  DiscountManagement: {
    // view: "View Product Compatibility",
    // create: "Create Product Compatibility",
    // update: "Update Product Compatibility",
    // delete: "Delete Product Compatibility",
    view: "View Discount",
    create: "Create Discount",
    update: "Update Discount",
    delete: "Delete Discount",
    exportData: "Export Discount",
  },
  StoreAddress: {
    view: "View Product",
    create: "Create Product",
    update: "Update Product",
    delete: "Delete Product",
  },
  ShippingShipStation: {
    view: "View Product",
    create: "Create Product",
    update: "Update Product",
    delete: "Delete Product",
  },
  SalesTeam: {
    view: "View Order",
    create: "Create Order",
    update: "Update Order",
    delete: "Delete Order",
  },
  OrderAddOn: {
    view: "View Order",
    create: "Create Order",
    update: "Update Order",
    delete: "Delete Order",
  },
  GiftCardManagement: {
    view: "View Gift Card",
    create: "Create Gift Card",
    update: "Update Gift Card",
    delete: "Delete Gift Card",
    viewCode: "Show Gift Card Code",
    addProduct: "Create Gift Card Product",
    updateProduct: "Update Gift Card Product",
    exportData: "Export Gift Card",
  },
};

export default permissions;
