import {
    Row,
    Col,
    Card,
    Descriptions,
    Image,
    Flex,
    Button,
    List,
    Avatar,
    Rate,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import "./AllCmsPage.css"
import fallback from "../../../images/fallback.webp";

export default function AboutUsPageView({ data }) {
    return (
        <Row gutter={[16, 24]} className="mt30">
            <Col span={24}>
                <Card
                    size="small"
                    title="Banner Section 1"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Banner Title</b>,
                                children: <>{data?.banner_title}</>,
                                span: 1,
                            },
                            {
                                label: <b>Banner Description</b>,
                                children: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data?.banner_description,
                                        }}
                                    >
                                        {/* {data?.banner_description} */}
                                    </div>
                                ),
                                span: 1,
                            },
                            {
                                label: <b>Banner Image</b>,
                                children: (
                                    <Flex align="center" gap={12}>
                                        {/* {data?.banner_description} */}
                                        {data?.banner_image?.map((img) => (
                                            <Image width={100} src={img?.url} className="homeBannerImage" />
                                        ))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Affiliations Section"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Affiliation Section Title</b>,
                                children: <>{data?.affiliation_title}</>,
                                span: 1,
                            },
                            {
                                label: <b>Affiliation Images</b>,
                                children: (
                                    <Flex align="center" wrap gap={12}>
                                        {/* {data?.banner_description} */}
                                        {data?.affiliations?.map((img) => (
                                            <Image
                                                width={100}
                                                src={img?.affiliation_image?.[0]?.url}
                                                className="homeAffiliationImage"
                                            />
                                        ))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Banner Section 2"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={3}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Banner Title</b>,
                                children: <>{data?.banner2_title}</>,
                                span: 3,
                            },
                            {
                                label: <b>Banner Description</b>,
                                children: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data?.banner2_description,
                                        }}
                                    >
                                        {/* {data?.banner_description} */}
                                    </div>
                                ),
                                span: 2,
                            },
                            {
                                label: <b>Banner Image</b>,
                                children: (
                                    <Flex align="center" gap={12}>
                                        {/* {data?.banner_description} */}
                                        {data?.banner2_image?.map((img) => (
                                            <Image width={200} src={img?.url} />
                                        ))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Video Section"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={2}
                        layout="vertical"
                        size="small"
                        items={[
                            {
                                label: <b>Video URL</b>,
                                children: <Link target="_blank" to={data?.youtube_link?.redirect_path}>{data?.youtube_link?.redirect_path}</Link>,
                                span: 2,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Why You Choose Us Section"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Why You Choose Us Section Title</b>,
                                children: <>{data?.why_you_choose_us_section_title
                                }</>,
                                span: 1,
                            },
                            // {
                            //     label: <b>Why You Choose Us</b>,
                            //     children: (
                            //         <Flex align="center" wrap gap={12}>
                            //             {data?.why_you_choose?.map((choose) => (
                            //                 <Card size="small">
                            //                     <Image width={60} src={choose?.image?.[0]?.url} className="trustImage" />
                            //                     <Flex justify="center" align="center" gap={8} className="trustImageContainer">
                            //                         <div>
                            //                             <b>{choose?.name}</b>
                            //                             <div
                            //                                 dangerouslySetInnerHTML={{
                            //                                     __html: choose?.description
                            //                                 }}
                            //                             >
                            //                                 {/* {data?.banner_description} */}
                            //                             </div></div>

                            //                     </Flex>
                            //                 </Card>
                            //             ))}
                            //         </Flex>
                            //     ),
                            //     span: 1,
                            // },
                            {
                                label: <b>Why You Choose Us</b>,
                                children: (
                                    <Row gutter={[16, 16]}>
                                        {data?.why_you_choose?.map((choose) => (
                                            <Col span={12}>
                                                <Card size="small" style={{ height: "100%", textAlign: "center" }}>
                                                    <Flex vertical justify="center" align="center" gap={8} className="trustImageContainer">
                                                        <Image width={60} src={choose?.image?.[0]?.url} className="trustImage" />
                                                        <div>
                                                            <b>{choose?.name}</b>
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: choose?.description
                                                                }}
                                                            >
                                                            </div></div>
                                                    </Flex>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>

            <Col span={24}>
                <Card
                    size="small"
                    title="Banner Section 3"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Banner Title</b>,
                                children: <>{data?.banner3_title}</>,
                                span: 1,
                            },
                            {
                                label: <b>Banner Description</b>,
                                children: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data?.banner3_description,
                                        }}
                                    >
                                    </div>
                                ),
                                span: 1,
                            },
                            {
                                label: <b>Banner Image</b>,
                                children: (
                                    <Row gutter={[10, 10]}>
                                        {/* {data?.banner_description} */}
                                        {data?.banner3_images?.map((img) => (
                                            <Col>
                                                <Image
                                                    width={100}
                                                    src={img?.image[0]?.url || fallback}
                                                    preview={img?.image[0]?.url ? true : false}
                                                    className="bannerSwiperImage" />
                                            </Col>
                                        ))}
                                    </Row>
                                ),
                                span: 1,
                            },
                            // {
                            //     label: <b>Banner Image</b>,
                            //     children: (
                            //         <Flex align="center" justify="space-between" gap={10} wrap>
                            //             {/* {data?.banner_description} */}
                            //             {data?.banner3_images?.map((img) => (
                            //                 <Image width={100} src={img?.image[0]?.url} className="bannerSwiperImage" />
                            //             ))}
                            //         </Flex>
                            //     ),
                            //     span: 1,
                            // },
                        ]}
                    />
                </Card>
            </Col>


            <Col span={24}>
                <Card
                    size="small"
                    title="Body Banner Section"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={3}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Body Banner Title</b>,
                                children: <>{data?.body_banner_title}</>,
                                span: 3,
                            },
                            {
                                label: <b>Body Banner Description</b>,
                                children: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data?.body_banner_description,
                                        }}
                                    ></div>
                                ),
                                span: 2,
                            },
                            {
                                label: <b>Section Image</b>,
                                children: (
                                    <Image width={200} src={data?.body_banner_image?.[0]?.url} />
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>

            <Col span={24}>
                <Card
                    size="small"
                    title="Location"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={3}
                        layout="vertical"
                        size="small"
                    // bordered
                    >
                        {/* <Descriptions.Item key={1} style={{ width: "100%", height: "100%" }}>
                            <Card size="small" style={{ width: "100%", height: "100%", textAlign: "center" }}>
                                <Flex wrap gap={12} vertical>
                                    <Image
                                        // width={100}
                                        style={{ maxHeight: 300, height: "100%" }}
                                        src={data?.aboutLocation?.florida?.[0]?.url}
                                        className="locationImage"
                                    />
                                    <b dangerouslySetInnerHTML={{
                                        __html: data?.aboutLocation?.florida_name,
                                    }}></b>
                                </Flex>
                            </Card>
                        </Descriptions.Item>
                        <Descriptions.Item key={2} style={{ width: "100%", height: "100%" }}>
                            <Card size="small" style={{ width: "100%", height: "100%", textAlign: "center" }}>
                                <Flex wrap gap={12} vertical>
                                    <Image
                                        // width={100}
                                        style={{ maxHeight: 300, height: "100%" }}
                                        src={data?.aboutLocation?.texas?.[0]?.url}
                                        className="locationImage"
                                    />
                                    <b dangerouslySetInnerHTML={{
                                        __html: data?.aboutLocation?.texas_name,
                                    }}></b>
                                </Flex>
                            </Card>
                        </Descriptions.Item>
                        <Descriptions.Item key={3} style={{ width: "100%", height: "100%" }}>
                            <Card size="small" style={{ width: "100%", height: "100%", textAlign: "center" }}>
                                <Flex wrap gap={12} vertical>
                                    <Image
                                        // width={100}
                                        style={{ maxHeight: 300, height: "100%" }}
                                        src={data?.aboutLocation?.tennessee?.[0]?.url}
                                        className="locationImage"
                                    />
                                    <b dangerouslySetInnerHTML={{
                                        __html: data?.aboutLocation?.tennessee_name,
                                    }}></b>
                                </Flex>
                            </Card>
                        </Descriptions.Item> */}

                        {['florida', 'texas', 'tennessee'].map((location) => (
                            <Descriptions.Item
                                key={location}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <Card
                                    size="small"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Flex wrap gap={12} vertical>
                                        <Image
                                            src={data?.aboutLocation?.[location]?.[0]?.url}
                                            className="locationImage"
                                            style={{
                                                maxHeight: 300,
                                                height: '100%'
                                            }}
                                        />
                                        <b
                                            dangerouslySetInnerHTML={{
                                                __html: data?.aboutLocation?.[`${location}_name`],
                                            }}
                                        ></b>
                                    </Flex>
                                </Card>
                            </Descriptions.Item>
                        ))}
                    </Descriptions>
                </Card>
            </Col>

            <Col span={24}>
                <Card
                    size="small"
                    title="Search Engine Listing"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Meta Title</b>,
                                children: <>{data?.meta_title}</>,
                                span: 1,
                            },
                            {
                                label: <b>Meta Description</b>,
                                children: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data?.meta_description,
                                        }}
                                    >
                                        {/* {data?.banner_description} */}
                                    </div>
                                ),
                                span: 1,
                            },
                            {
                                label: <b>Banner Image</b>,
                                children: (
                                    <Flex align="center" gap={12}>
                                        {/* {data?.banner_description} */}
                                        {data?.feature_image?.map((img) => (
                                            <Image width={100} src={img?.url} className="searchEngineImage" />
                                        ))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
        </Row>
    );
}
