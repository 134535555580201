import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  DatePicker,
  InputNumber,
  Upload,
  Select,
  Popover,
  Spin,
} from "antd";
import PageHeader from "../../../components/pageHeader";
import Dragger from "antd/es/upload/Dragger";
import UploadUtils from "../../../utilities/uploadUtils";
import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import dayjs from "dayjs";
import SortableService from "../../../components/home-draggable/SortableService";
import SortableAffiliation from "../../../components/home-draggable/SortableAffiliation";
import SortableTrustOurWork from "../../../components/home-draggable/SortableTrustOurWork";
import SortableWholesalerBenefit from "../../../components/home-draggable/SortableWholesalerBenefit";
import SortableJeepBuildBenefit from "../../../components/home-draggable/SortableJeepBuildBenefit";
import SortableTestimonial from "../../../components/home-draggable/SortableTestimonial";
import roleAndPermissions from "../../../constants/permissions";
import AuthChecker from "../../../components/authChecker";
import HomePageView from "../pages-view/HomePageView";

const { TextArea } = Input;

const HomePageForm = ({ mode, formFor, editLayout, title }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState({
    ...formFor?.en?.data,
    testimonials: formFor?.en?.data?.testimonials?.map((t) => {
      return {
        ...t,
        testimonial_date: dayjs(t.testimonial_date),
      };
    }),
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    // console.log(values);
    try {
      values.affiliations = values.affiliations.map((i) => ({
        affiliation_image: UploadUtils.processUploadImage(i.affiliation_image),
      }));
      values.banner_image = UploadUtils.processUploadImage(
        values.banner_image,
        true
      );
      values.body_banner_image = UploadUtils.processUploadImage(
        values.body_banner_image
      );
      values.our_works = values.our_works.map((i) => ({
        work_name: i.work_name,
        work_image: UploadUtils.processUploadImage(i.work_image),
      }));
      values.services = values.services.map((i) => ({
        service_name: i.service_name,
        service_image: UploadUtils.processUploadImage(i.service_image),
        // link_type: i?.link_type,
        redirect_path: i?.redirect_path,
      }));
      values.wholesalers_benefits = values.wholesalers_benefits.map((i) => ({
        benefit_name: i.benefit_name,
        benefit_image: UploadUtils.processUploadImage(i.benefit_image),
      }));
      values.jeep_build_section_image = UploadUtils.processUploadImage(
        values.jeep_build_section_image
      );
      values.feature_image = UploadUtils.processUploadImage(
        values.feature_image
      );
      values.testimonials = values.testimonials.map((i) => ({
        // testimonial_add_date: dayjs(i.testimonial_add_date).format("YYYY-MM-DD"),
        customer_name: i.customer_name,
        customer_rating: i.customer_rating,
        customer_testimonial: i.customer_testimonial,
        testimonial_date: i.testimonial_date,
        customer_image: UploadUtils.processUploadImage(i.customer_image),
      }));
      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          feature_image: values.feature_image,
        },
      ];

      setData(values);

      await editLayout(values, "Home", seo_setting);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    let newData = {
      ...formFor?.en?.data,
      testimonials: formFor?.en?.data?.testimonials?.map((t) => {
        return {
          ...t,
          testimonial_date: dayjs(t.testimonial_date),
        };
      }),
    };
    setData(newData);
  }, [formFor]);

  
  if(mode !== "View") {
  return (
    <Form
      form={form}
      disabled={mode === "View" || loading === true}
      layout="vertical"
      initialValues={data}
      onFinish={(values) => handleSubmit(values)}
    >
      <PageHeader title={`${title?.replace("-", " ")} Page`} />
      <Row gutter={16} className="mt30">
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card
            size="small"
            title="Banner Section"
            bodyStyle={{ padding: "24px 24px 0px 24px", paddingBottom: 0 }}
          >
            <Form.Item
              label="Banner Title"
              name="banner_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Banner Description"
              name="banner_description"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <ReactQuill
                theme="snow"
                rows={4}
                readOnly={mode === "View" ? true : false}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Banner Image{" "}
                  <Popover placement="right" title="W * H" content="700 x 700">
                    <QuestionCircleOutlined />
                  </Popover>
                </span>
              }
              name="banner_image"
              {...UploadUtils.formItemProps}
              wrapperCol={{ span: 24 }}
            >
              <Upload {...UploadUtils.buttonPreviewMultipleProps}>
                <UploadUtils.CardContent />
              </Upload>
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Services Section">
            <Form.Item
              label="Services Section Title"
              name="services_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <SortableService data={data} setData={setData} form={form} />
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Affiliations Section">
            <Form.Item
              label="Affiliation Section Title"
              name="affiliation_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <SortableAffiliation data={data} setData={setData} form={form} />
            {/* <Form.List name="affiliations">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 30,
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {fields.map((field, index) => (
                      <Card
                        size="small"
                        title={`Affiliation ${index + 1}`}
                        key={field.key}
                        extra={
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      >
                        <Form.Item
                          label="Affiliation Image"
                          name={[field.name, "affiliation_image"]}
                          {...UploadUtils.formItemProps}
                          wrapperCol={{ span: 24 }}
                        >
                          <Upload {...UploadUtils.buttonPreviewProps}>
                            <UploadUtils.CardContent />
                          </Upload>
                        </Form.Item>
                      </Card>
                    ))}
                  </div>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Affiliation
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List> */}
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Video Section">
            <Form.Item
              label="Main Title"
              name="youtube_main_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Sub Title"
              name="youtube_sub_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>

            {/* <Form.Item
                  label="Name"
                  name={["talk_to_live", "name"]}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item> */}
            {/* 
            <Form.Item
              label={"Select Link Type"}
              name={["youtube_link", "link_type"]}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Select
                options={[
                  {
                    value: "internal",
                    label: "Internal",
                  },
                  {
                    value: "external",
                    label: "External"
                  }
                ]}
              />
            </Form.Item> */}

            {/* <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.talk_to_live?.link_type !==
                currentValues.talk_to_live?.link_type
              }
              noStyle
            >
              {({ getFieldValue }) => {
                let type = getFieldValue(["youtube_link", "link_type"]);

                return (
                  <>
                    {type === "external" && (
                      <Form.Item
                        label={"Link Url"}
                        name={["youtube_link", "redirect_path"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    )}
                    {type === "internal" && (
                      <>
                        <Form.Item
                          label={"Link Url"}
                          name={["youtube_link", "redirect_path"]}
                        >
                         <Input addonBefore={process.env.REACT_APP_CUSTOMER_SITE_URL} />
                        </Form.Item>
                      </>
                    )}
                  </>
                );
              }}
            </Form.Item> */}

            <Form.Item
              label={"Link Url"}
              name={["youtube_link", "redirect_path"]}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Trust Our Work Section">
            <Form.Item
              label="Trust Our Work Section Title"
              name="trust_our_work_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <SortableTrustOurWork data={data} setData={setData} form={form} />
            {/* <Form.List name="our_works">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      size="small"
                      title={`work ${index + 1}`}
                      key={field.key}
                    >
                      <Form.Item
                        label="Work Name"
                        name={[field.name, "work_name"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Work Image"
                        name={[field.name, "work_image"]}
                        {...UploadUtils.formItemProps}
                        wrapperCol={{ span: 24 }}
                      >
                        <Upload {...UploadUtils.buttonPreviewProps}>
                          <UploadUtils.CardContent />
                        </Upload>
                      </Form.Item>
                    </Card>
                  ))}
                </div>
              )}
            </Form.List> */}
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Benefits For WholeSalers Section">
            <Form.Item
              label="Section Title"
              name={"wholeSalers_section_title"}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Section Description"
              name={"wholeSalers_section_description"}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <ReactQuill
                theme="snow"
                rows={4}
                readOnly={mode === "View" ? true : false}
              />
            </Form.Item>
            <SortableWholesalerBenefit
              data={data}
              setData={setData}
              form={form}
            />
            {/* <Form.List name="wholesalers_benefits">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      size="small"
                      title={`benefit ${index + 1}`}
                      style={{ maxWidth: 350 }}
                      key={field.key}
                    >
                      <Form.Item
                        label="Benefit Name"
                        name={[field.name, "benefit_name"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Benefit Image"
                        name={[field.name, "benefit_image"]}
                        {...UploadUtils.formItemProps}
                        wrapperCol={{ span: 24 }}
                      >
                        <Upload {...UploadUtils.buttonPreviewProps}>
                          <UploadUtils.CardContent />
                        </Upload>
                      </Form.Item>
                    </Card>
                  ))}
                </div>
              )}
            </Form.List> */}
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Benefits For Jeep Build Section">
            <Form.Item
              label="Section Title"
              name={"jeep_build_section_title"}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Section Description"
              name={"jeep_build_section_description"}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <ReactQuill
                theme="snow"
                rows={4}
                readOnly={mode === "View" ? true : false}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Section Image{" "}
                  <Popover placement="right" title="W * H" content="440 x 310">
                    <QuestionCircleOutlined />
                  </Popover>
                </span>
              }
              name={"jeep_build_section_image"}
              {...UploadUtils.formItemProps}
              wrapperCol={{ span: 24 }}
            >
              <Upload {...UploadUtils.buttonPreviewProps}>
                <UploadUtils.CardContent />
              </Upload>
            </Form.Item>
            <SortableJeepBuildBenefit
              data={data}
              setData={setData}
              form={form}
            />
            {/* <Form.List name="jeep_build_benefits">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "row",
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      size="small"
                      title={`benefit ${index + 1}`}
                      style={{ width: "48%" }}
                      key={field.key}
                    >
                      <Form.Item
                        label="Benefit Name"
                        name={[field.name, "benefit_name"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Card>
                  ))}
                </div>
              )}
            </Form.List> */}
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Body Banner Section">
            <Form.Item
              label="Body Banner Title"
              name="body_banner_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Body Banner Description"
              name="body_banner_description"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <ReactQuill
                theme="snow"
                rows={4}
                readOnly={mode === "View" ? true : false}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Body Banner Image{" "}
                  <Popover placement="right" title="W * H" content="460 x 533">
                    <QuestionCircleOutlined />
                  </Popover>
                </span>
              }
              name="body_banner_image"
              {...UploadUtils.formItemProps}
              wrapperCol={{ span: 24 }}
            >
              <Upload {...UploadUtils.buttonPreviewProps}>
                <UploadUtils.CardContent />
              </Upload>
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Testimonial Section">
            <Form.Item
              label="Testimonial Section Title "
              name="testimonial_section_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <SortableTestimonial data={data} setData={setData} form={form} />
            {/* <Form.List name="testimonials">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 30,
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {fields.map((field, index) => (
                      <Card
                        size="small"
                        title={`Testimonial ${index + 1}`}
                        extra={
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                        style={{ maxWidth: 380 }}
                        key={field.key}
                      >
                        <Form.Item
                          label="Testimonial Date"
                          name={[field.name, "testimonial_date"]}
                          wrapperCol={{ span: 24 }}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <DatePicker
                            format={(d) => dayjs(d).format("MMM D, YYYY")}
                            disabledDate={(date) => !date.isBefore(dayjs())}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Customer Image"
                          name={[field.name, "customer_image"]}
                          {...UploadUtils.formItemProps}
                          wrapperCol={{ span: 24 }}
                        >
                          <Upload {...UploadUtils.buttonPreviewProps}>
                            <UploadUtils.CardContent />
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          label="Customer Name"
                          name={[field.name, "customer_name"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Customer Rating"
                          name={[field.name, "customer_rating"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <InputNumber min={0} max={5} />
                        </Form.Item>
                        <Form.Item
                          label="Customer Testimonial"
                          name={[field.name, "customer_testimonial"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <TextArea />
                        </Form.Item>
                      </Card>
                    ))}
                  </div>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Testimonial
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List> */}
          </Card>
        </Col>
        <AuthChecker
          notPermissionCase={
            <Col span={24} style={{ marginBottom: 30 }}>
              <Card size="small" title="Search Engine Listing">
                <Form.Item
                  label="Meta Title"
                  name="meta_title"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="Meta Description"
                  name="meta_description"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <TextArea disabled />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      Feature Image{" "}
                      <Popover
                        placement="right"
                        title="W * H"
                        content="1200 x 675 OR 1600 x 900"
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </span>
                  }
                  name="feature_image"
                  {...UploadUtils.formItemProps}
                  wrapperCol={{ span: 24 }}
                >
                  <Upload {...UploadUtils.buttonPreviewProps} disabled>
                    <UploadUtils.CardContent />
                  </Upload>
                </Form.Item>
              </Card>
            </Col>
          }
          permissions={[
            roleAndPermissions.PageManagement.createSEO,
            roleAndPermissions.PageManagement.updateSEO,
          ]}
        >
          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Search Engine Listing">
              <Form.Item
                label="Meta Title"
                name="meta_title"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Meta Description"
                name="meta_description"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Feature Image{" "}
                    <Popover
                      placement="right"
                      title="W * H"
                      content="1200 x 675 OR 1600 x 900"
                    >
                      <QuestionCircleOutlined />
                    </Popover>
                  </span>
                }
                name="feature_image"
                {...UploadUtils.formItemProps}
                wrapperCol={{ span: 24 }}
              >
                <Upload {...UploadUtils.buttonPreviewProps}>
                  <UploadUtils.CardContent />
                </Upload>
              </Form.Item>
            </Card>
          </Col>
        </AuthChecker>
        <Col span={24}>
          <Flex justify="end" style={{ padding: 24, paddingRight: 0 }} gap={16}>
            <Form.Item noStyle>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                Update Page
              </Button>
            </Form.Item>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
  }
  if(mode === "View") {
  return (
    <> <PageHeader title={`${title?.replace("-", " ")} Page`} />
     <HomePageView data={data} />
    </>
  );
}
};

export default HomePageForm;
