import React, { useState } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  Upload,
  Select,
  Popover,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import PageHeader from "../../../components/pageHeader";
import UploadUtils from "../../../utilities/uploadUtils";
import ReactQuill from "react-quill";
import SortDraggableList from "../../../components/SortDraggableList";
import roleAndPermissions from "../../../constants/permissions";
import AuthChecker from "../../../components/authChecker";
import ContactUsPageView from "../pages-view/ContactUsPageView";

const { TextArea } = Input;

const SortableLocation = ({ data, setData, form, }) => {
  const items =
    data?.contact_locations_details?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderDragContent = (field) => (
    <div>
      <Row gutter={16} style={{ margin: 0 }}>
        <Col span={12}>
          <Form.Item
            label="Location Name"
            name={[field.name, "name"]}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Location Subtitle"
            name={[field.name, "subtitle"]}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={
              <span>
                Location Image{" "}
                <Popover placement="right" title="W * H" content="1920 x 795">
                  <QuestionCircleOutlined />
                </Popover>
              </span>
            }
            name={[field.name, "location_image"]}
            {...UploadUtils.formItemProps}
          // rules={[{ required: true, message: "This field is required" }]}
          >
            <Upload {...UploadUtils.buttonPreviewProps}>
              <UploadUtils.CardContent />
            </Upload>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
  return (
    <SortDraggableList
      items={items}
      renderContent={renderDragContent}
      setData={setData}
      form={form}
      formField="contact_locations_details"
      addContent={true}
      showRemoveOption={true}
      cardTitle="Location"
    />
  );
};

const ContactUsForm = ({ mode, formFor, editLayout, title }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(formFor?.en?.data);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      values.feature_image = UploadUtils.processUploadImage(
        values.feature_image
      );
      values.contact_locations_details = values.contact_locations_details.map(
        (location) => ({
          ...location,
          location_image: UploadUtils.processUploadImage(
            location.location_image
          ),
        })
      );
      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          feature_image: values.feature_image,
        },
      ];
      setData(data);
      await editLayout(values, "Contact Us", seo_setting);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  if (mode !== "View") {
    return (
      <Form
        form={form}
        disabled={mode === "View" || loading === true}
        layout="vertical"
        initialValues={data}
        onFinish={(values) => handleSubmit(values)}
      >
        <PageHeader title={`${title.replace("-", " ")} Page`} />
        <Row gutter={16} className="mt30">
          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Contact Title">
              <Form.Item
                label="Main Title"
                name="main_title"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Card>
          </Col>

          <Col span={24} >
            <Card
              size="small"
              title="Contact Details"
              style={{ marginBottom: 30 }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 20,
                  flexDirection: "column",
                }}
              >
                <Card size="small" title="Advice, Sales & Support">
                  <Form.Item
                    label="Toll Free Number"
                    name={["advice_sales_support", "sales_toll_free_number"]}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="US Local Number"
                    name={["advice_sales_support", "us_local_number"]}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Card>
                <Card size="small" title="Talk To Live">
                  <Form.Item
                    label="Name"
                    name={["talk_to_live", "name"]}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label={"Select Link Type"}
                    name={["talk_to_live", "link_type"]}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Select
                      options={[
                        {
                          value: "internal",
                          label: "Internal",
                        },
                        {
                          value: "external",
                          label: "External",
                        },
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.talk_to_live?.link_type !==
                      currentValues.talk_to_live?.link_type
                    }
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      let type = getFieldValue(["talk_to_live", "link_type"]);

                      return (
                        <>
                          {type === "external" && (
                            <Form.Item
                              label={"Link Url"}
                              name={["talk_to_live", "redirect_path"]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          )}
                          {type === "internal" && (
                            <>
                              <Form.Item
                                label={"Link Url"}
                                name={["talk_to_live", "redirect_path"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required",
                                  },
                                ]}
                              >
                                <Input
                                  addonBefore={
                                    process.env.REACT_APP_CUSTOMER_SITE_URL
                                  }
                                />
                              </Form.Item>
                            </>
                          )}
                        </>
                      );
                    }}
                  </Form.Item>
                </Card>
                <Card size="small" title="Wholesale">
                  <Form.Item
                    label="Toll Free Number"
                    name={["wholesale", "toll_free_number"]}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Card>
              </div>
            </Card>
          </Col>

          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Locations">
              <SortableLocation data={data} setData={setData} form={form} />
              {/* <Form.List name="contact_locations_details" initialValues={[]}>
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "column",
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      size="small"
                      title={`Location ${index + 1}`}
                      key={field.key}
                      extra={
                        <>
                          {fields.length > 1 && (
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          )}
                        </>
                      }
                    >
                      <Row gutter={16} style={{ margin: 0 }}>
                        <Col span={12}>
                          <Form.Item
                            label="Location Name"
                            name={[field.name, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Location Subtitle"
                            name={[field.name, "subtitle"]}
                            rules={[
                              {
                                required: true,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Location Image"
                            name={[field.name, "location_image"]}
                            {...UploadUtils.formItemProps}
                            rules={[
                              {
                                required: true,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Upload {...UploadUtils.buttonPreviewProps}>
                              <UploadUtils.CardContent />
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Another Location
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>
            */}
            </Card>

            {/* <Card size="small" title="Locations">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              onDragStart={(event) => setActiveId(event.active.id)}
            >
              <SortableContext
                items={items.map((item) => item.id)}
                strategy={verticalListSortingStrategy} // Using verticalListSortingStrategy
              >
                <Form.List
                  name="contact_locations_details"
                  initialValues={data.contact_locations_details}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <SortableItem
                          key={items[index].id}
                          id={items[index].id}
                          index={index}
                          field={field}
                          remove={remove}
                        />
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Another Location
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </SortableContext>
              
            </DndContext>
          </Card> */}
          </Col>
          <AuthChecker
            notPermissionCase={
              <Col span={24} style={{ marginBottom: 30 }}>
                <Card size="small" title="Search Engine Listing">
                  <Form.Item
                    label="Meta Title"
                    name="meta_title"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label="Meta Description"
                    name="meta_description"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <TextArea disabled />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        Feature Image{" "}
                        <Popover
                          placement="right"
                          title="W * H"
                          content="1200 x 675 OR 1600 x 900"
                        >
                          <QuestionCircleOutlined />
                        </Popover>
                      </span>
                    }
                    name="feature_image"
                    {...UploadUtils.formItemProps}
                    wrapperCol={{ span: 24 }}
                  >
                    <Upload {...UploadUtils.buttonPreviewProps} disabled>
                      <UploadUtils.CardContent />
                    </Upload>
                  </Form.Item>
                </Card>
              </Col>
            }
            permissions={[
              roleAndPermissions.PageManagement.createSEO,
              roleAndPermissions.PageManagement.updateSEO,
            ]}
          >
            <Col span={24} style={{ marginBottom: 30 }}>
              <Card size="small" title="Search Engine Listing">
                <Form.Item
                  label="Meta Title"
                  name="meta_title"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Meta Description"
                  name="meta_description"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <TextArea />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      Feature Image{" "}
                      <Popover
                        placement="right"
                        title="W * H"
                        content="1200 x 675 OR 1600 x 900"
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </span>
                  }
                  name="feature_image"
                  {...UploadUtils.formItemProps}
                  wrapperCol={{ span: 24 }}
                >
                  <Upload {...UploadUtils.buttonPreviewProps}>
                    <UploadUtils.CardContent />
                  </Upload>
                </Form.Item>
              </Card>
            </Col>
          </AuthChecker>

          <Col span={24}>
            <Flex justify="end" style={{ padding: 24, paddingRight: 0 }} gap={16}>
              <Form.Item noStyle>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Update Page
                </Button>
              </Form.Item>
            </Flex>
          </Col>
        </Row>
      </Form>
    );
  }
  if (mode === "View") {
    return (
      <> <PageHeader title={`${title?.replace("-", " ")} Page`} />
        <ContactUsPageView data={data} />
      </>
    );
  }
};

export default ContactUsForm;
